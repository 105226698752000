import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import CTA from "../components/CTA/CTA"
import Features from "../components/Features/Features"
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react'
import { useTranslation } from 'react-i18next';
import '../components/i18n'; // Importing i18n configuration

const Index = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title="Home" />
      <Layout>
        <SpeedInsights />
        <Analytics />
        <BannerModule
          title={t('mainTitle')}
          subTitle={t('mainSubTitle')}
        />
        <div id='aboutUs'>
          <BasicTextModule
            title={t('aboutUs')}
            content={t('aboutUsContent')}
          />
        </div>
        <Features
          title={t('features')}
          introduction={t('featuresContent')}
        />
        <CTA
            title={t('ctaTitle')}
            content={t('ctaContent')}
            link="/contact"
            linkText={t('ctaLinkText')}
        />
      </Layout>
    </>
  )
}

export default Index
