import {useTranslation} from 'react-i18next';
import '../components/i18n'; // Importing i18n configuration

const useFeaturedProduct = () => {
    const {t} = useTranslation();

    return [
        {
            title: t('customSoftwareDevelopmentTitle'),
            introduction: t('customSoftwareDevelopmentIntro'),
            alt: 'Custom software development in Andorra',
            headerImage: {
                "gatsbyImageData": {
                    "images": {
                        "sources": [
                            {
                                "srcSet": "/custom-software-development-2.webp",
                                "sizes": "(min-width: 2000px) 2000px, 100vw",
                                "type": "image/png"
                            }
                        ]
                    },
                    "layout": "constrained",
                    "width": 2000,
                    "height": 1333,
                    "placeholder": {
                        "fallback": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gIcSUNDX1BST0ZJTEUAAQEAAAIMbGNtcwIQAABtbnRyUkdCIFhZWiAH3AABABkAAwApADlhY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAApkZXNjAAAA/AAAAF5jcHJ0AAABXAAAAAt3dHB0AAABaAAAABRia3B0AAABfAAAABRyWFlaAAABkAAAABRnWFlaAAABpAAAABRiWFlaAAABuAAAABRyVFJDAAABzAAAAEBnVFJDAAABzAAAAEBiVFJDAAABzAAAAEBkZXNjAAAAAAAAAANjMgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB0ZXh0AAAAAElYAABYWVogAAAAAAAA9tYAAQAAAADTLVhZWiAAAAAAAAADFgAAAzMAAAKkWFlaIAAAAAAAAG+iAAA49QAAA5BYWVogAAAAAAAAYpkAALeFAAAY2lhZWiAAAAAAAAAkoAAAD4QAALbPY3VydgAAAAAAAAAaAAAAywHJA2MFkghrC/YQPxVRGzQh8SmQMhg7kkYFUXdd7WtwegWJsZp8rGm/fdPD6TD////bAEMAEAsMDgwKEA4NDhIREBMYKBoYFhYYMSMlHSg6Mz08OTM4N0BIXE5ARFdFNzhQbVFXX2JnaGc+TXF5cGR4XGVnY//bAEMBERISGBUYLxoaL2NCOEJjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY//AABEIAA0AFAMBIgACEQEDEQH/xAAYAAADAQEAAAAAAAAAAAAAAAAAAgMFBv/EACIQAAEEAgIBBQAAAAAAAAAAAAEAAgMEBRETISISMUFxgf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwEAAhEDEQA/AJ5CyyhjPUzXPL4R/fyfxczcirxRsYN8wPltLkrUslsAuOohpqvjrrYobDpK8czjoAuA2B37dJtbdrNLhvpCRCEP/9k="
                    }
                }
            }
        },
        {
            title: t('fractionalCTOTitle'),
            introduction: t('fractionalCTOIntro'),
            alt: 'Fractional CTO in Andorra',
            headerImage: {
                "gatsbyImageData": {
                    "images": {
                        "sources": [
                            {
                                "srcSet": "/fractional-cto-2.webp",
                                "sizes": "(min-width: 2000px) 2000px, 100vw",
                                "type": "image/png"
                            }
                        ]
                    },
                    "layout": "constrained",
                    "width": 2000,
                    "height": 1333,
                    "placeholder": {
                        "fallback": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gIcSUNDX1BST0ZJTEUAAQEAAAIMbGNtcwIQAABtbnRyUkdCIFhZWiAH3AABABkAAwApADlhY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAApkZXNjAAAA/AAAAF5jcHJ0AAABXAAAAAt3dHB0AAABaAAAABRia3B0AAABfAAAABRyWFlaAAABkAAAABRnWFlaAAABpAAAABRiWFlaAAABuAAAABRyVFJDAAABzAAAAEBnVFJDAAABzAAAAEBiVFJDAAABzAAAAEBkZXNjAAAAAAAAAANjMgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB0ZXh0AAAAAElYAABYWVogAAAAAAAA9tYAAQAAAADTLVhZWiAAAAAAAAADFgAAAzMAAAKkWFlaIAAAAAAAAG+iAAA49QAAA5BYWVogAAAAAAAAYpkAALeFAAAY2lhZWiAAAAAAAAAkoAAAD4QAALbPY3VydgAAAAAAAAAaAAAAywHJA2MFkghrC/YQPxVRGzQh8SmQMhg7kkYFUXdd7WtwegWJsZp8rGm/fdPD6TD////bAEMAEAsMDgwKEA4NDhIREBMYKBoYFhYYMSMlHSg6Mz08OTM4N0BIXE5ARFdFNzhQbVFXX2JnaGc+TXF5cGR4XGVnY//bAEMBERISGBUYLxoaL2NCOEJjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY//AABEIAA0AFAMBIgACEQEDEQH/xAAYAAADAQEAAAAAAAAAAAAAAAAAAgMFBv/EACIQAAEEAgIBBQAAAAAAAAAAAAEAAgMEBRETISISMUFxgf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwEAAhEDEQA/AJ5CyyhjPUzXPL4R/fyfxczcirxRsYN8wPltLkrUslsAuOohpqvjrrYobDpK8czjoAuA2B37dJtbdrNLhvpCRCEP/9k="
                    }
                }
            }
        },
        {
            title: t('continuousSoftwareDevelopmentTitle'),
            introduction: t('continuousSoftwareDevelopmentIntro'),
            alt: 'Continuous software maintenance in Andorra',
            headerImage: {
                "gatsbyImageData": {
                    "images": {
                        "sources": [
                            {
                                // "srcSet": "/continuous-software-development.png",
                                "srcSet": "/continuous-software-development-2.webp",
                                "sizes": "(min-width: 2000px) 2000px, 100vw",
                                "type": "image/png"
                            }
                        ]
                    },
                    "layout": "constrained",
                    "width": 2000,
                    "height": 1333,
                    "placeholder": {
                        "fallback": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gIcSUNDX1BST0ZJTEUAAQEAAAIMbGNtcwIQAABtbnRyUkdCIFhZWiAH3AABABkAAwApADlhY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAApkZXNjAAAA/AAAAF5jcHJ0AAABXAAAAAt3dHB0AAABaAAAABRia3B0AAABfAAAABRyWFlaAAABkAAAABRnWFlaAAABpAAAABRiWFlaAAABuAAAABRyVFJDAAABzAAAAEBnVFJDAAABzAAAAEBiVFJDAAABzAAAAEBkZXNjAAAAAAAAAANjMgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB0ZXh0AAAAAElYAABYWVogAAAAAAAA9tYAAQAAAADTLVhZWiAAAAAAAAADFgAAAzMAAAKkWFlaIAAAAAAAAG+iAAA49QAAA5BYWVogAAAAAAAAYpkAALeFAAAY2lhZWiAAAAAAAAAkoAAAD4QAALbPY3VydgAAAAAAAAAaAAAAywHJA2MFkghrC/YQPxVRGzQh8SmQMhg7kkYFUXdd7WtwegWJsZp8rGm/fdPD6TD////bAEMAEAsMDgwKEA4NDhIREBMYKBoYFhYYMSMlHSg6Mz08OTM4N0BIXE5ARFdFNzhQbVFXX2JnaGc+TXF5cGR4XGVnY//bAEMBERISGBUYLxoaL2NCOEJjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY//AABEIAA0AFAMBIgACEQEDEQH/xAAYAAADAQEAAAAAAAAAAAAAAAAAAgMFBv/EACIQAAEEAgIBBQAAAAAAAAAAAAEAAgMEBRETISISMUFxgf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwEAAhEDEQA/AJ5CyyhjPUzXPL4R/fyfxczcirxRsYN8wPltLkrUslsAuOohpqvjrrYobDpK8czjoAuA2B37dJtbdrNLhvpCRCEP/9k="
                    }
                }
            }
        },
        {
            title: t('dedicatedTeamsTitle'),
            introduction: t('dedicatedTeamsIntro'),
            alt: 'Dedicated teams software development in Andorra',
            headerImage: {
                "gatsbyImageData": {
                    "images": {
                        "sources": [
                            {
                                "srcSet": "/dedicated-teams-2.webp",
                                "sizes": "(min-width: 2000px) 2000px, 100vw",
                                "type": "image/png"
                            }
                        ]
                    },
                    "layout": "constrained",
                    "width": 2000,
                    "height": 1333,
                    "placeholder": {
                        "fallback": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gIcSUNDX1BST0ZJTEUAAQEAAAIMbGNtcwIQAABtbnRyUkdCIFhZWiAH3AABABkAAwApADlhY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAApkZXNjAAAA/AAAAF5jcHJ0AAABXAAAAAt3dHB0AAABaAAAABRia3B0AAABfAAAABRyWFlaAAABkAAAABRnWFlaAAABpAAAABRiWFlaAAABuAAAABRyVFJDAAABzAAAAEBnVFJDAAABzAAAAEBiVFJDAAABzAAAAEBkZXNjAAAAAAAAAANjMgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB0ZXh0AAAAAElYAABYWVogAAAAAAAA9tYAAQAAAADTLVhZWiAAAAAAAAADFgAAAzMAAAKkWFlaIAAAAAAAAG+iAAA49QAAA5BYWVogAAAAAAAAYpkAALeFAAAY2lhZWiAAAAAAAAAkoAAAD4QAALbPY3VydgAAAAAAAAAaAAAAywHJA2MFkghrC/YQPxVRGzQh8SmQMhg7kkYFUXdd7WtwegWJsZp8rGm/fdPD6TD////bAEMAEAsMDgwKEA4NDhIREBMYKBoYFhYYMSMlHSg6Mz08OTM4N0BIXE5ARFdFNzhQbVFXX2JnaGc+TXF5cGR4XGVnY//bAEMBERISGBUYLxoaL2NCOEJjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY//AABEIAA0AFAMBIgACEQEDEQH/xAAYAAADAQEAAAAAAAAAAAAAAAAAAgMFBv/EACIQAAEEAgIBBQAAAAAAAAAAAAEAAgMEBRETISISMUFxgf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwEAAhEDEQA/AJ5CyyhjPUzXPL4R/fyfxczcirxRsYN8wPltLkrUslsAuOohpqvjrrYobDpK8czjoAuA2B37dJtbdrNLhvpCRCEP/9k="
                    }
                }
            }
        },
        /*,
    {
      "title": "Apple Airpods Pro",
      "introduction": "AirPods Pro are wireless Bluetooth in-ear headphones designed by Apple",
      "headerImage": {
        "gatsbyImageData": {
          "images": {
            "sources": [
              {
                "srcSet": "https://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=500&h=333&q=50&fm=webp 500w,\nhttps://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=1000&h=667&q=50&fm=webp 1000w,\nhttps://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=2000&h=1333&q=50&fm=webp 2000w",
                "sizes": "(min-width: 2000px) 2000px, 100vw",
                "type": "image/webp"
              },
              {
                "srcSet": "https://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=500&h=333&q=50&fm=avif 500w,\nhttps://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=1000&h=667&q=50&fm=avif 1000w,\nhttps://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=2000&h=1333&q=50&fm=avif 2000w",
                "sizes": "(min-width: 2000px) 2000px, 100vw",
                "type": "image/avif"
              }
            ],
            "fallback": {
              "src": "https://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=2000&h=1333&fl=progressive&q=50&fm=jpg",
              "srcSet": "https://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=500&h=333&fl=progressive&q=50&fm=jpg 500w,\nhttps://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=1000&h=667&fl=progressive&q=50&fm=jpg 1000w,\nhttps://images.ctfassets.net/jou8fvkfe5pw/31cGhK2Odf4okLinsIjZuO/21f372b3c3e445219b0d9ac5b9e0c114/apple-airpods-pro.jpg?w=2000&h=1333&fl=progressive&q=50&fm=jpg 2000w",
              "sizes": "(min-width: 2000px) 2000px, 100vw"
            }
          },
          "layout": "constrained",
          "width": 2000,
          "height": 1333,
          "placeholder": {
            "fallback": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gIcSUNDX1BST0ZJTEUAAQEAAAIMbGNtcwIQAABtbnRyUkdCIFhZWiAH3AABABkAAwApADlhY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAApkZXNjAAAA/AAAAF5jcHJ0AAABXAAAAAt3dHB0AAABaAAAABRia3B0AAABfAAAABRyWFlaAAABkAAAABRnWFlaAAABpAAAABRiWFlaAAABuAAAABRyVFJDAAABzAAAAEBnVFJDAAABzAAAAEBiVFJDAAABzAAAAEBkZXNjAAAAAAAAAANjMgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB0ZXh0AAAAAElYAABYWVogAAAAAAAA9tYAAQAAAADTLVhZWiAAAAAAAAADFgAAAzMAAAKkWFlaIAAAAAAAAG+iAAA49QAAA5BYWVogAAAAAAAAYpkAALeFAAAY2lhZWiAAAAAAAAAkoAAAD4QAALbPY3VydgAAAAAAAAAaAAAAywHJA2MFkghrC/YQPxVRGzQh8SmQMhg7kkYFUXdd7WtwegWJsZp8rGm/fdPD6TD////bAEMAEAsMDgwKEA4NDhIREBMYKBoYFhYYMSMlHSg6Mz08OTM4N0BIXE5ARFdFNzhQbVFXX2JnaGc+TXF5cGR4XGVnY//bAEMBERISGBUYLxoaL2NCOEJjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY//AABEIAA0AFAMBIgACEQEDEQH/xAAYAAADAQEAAAAAAAAAAAAAAAAAAgMFBv/EACIQAAEEAgIBBQAAAAAAAAAAAAEAAgMEBRETISISMUFxgf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwEAAhEDEQA/AJ5CyyhjPUzXPL4R/fyfxczcirxRsYN8wPltLkrUslsAuOohpqvjrrYobDpK8czjoAuA2B37dJtbdrNLhvpCRCEP/9k="
          }
        }
      }
    }*/]
}

export default useFeaturedProduct
