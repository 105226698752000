import * as React from "react"
import Button from "../Button/Button"
import {CTAStyles} from "./CTAStyles"

const CTA = ({title, content, link, linkText}) => {
    return (
        <CTAStyles className="section">
            <div className="container">
                <div>
                    {title && <p>{title}</p>}
                    {content && <p style={{marginBottom: "30px"}}>{content}</p>}

                    <Button text={linkText} as="a" to={link} arrow={false}/>
                </div>
            </div>
        </CTAStyles>
    )
}

export default CTA
