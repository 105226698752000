import * as React from "react"
// import { Link } from "gatsby"
// import Button from "../Button/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FeaturedProductStyles } from "./FeaturesStyles"

const FeaturedProduct = ({ feature }) => {
  const { gatsbyPath, headerImage, title, introduction, alt } = feature
  const image = getImage(headerImage)

  return (
    <FeaturedProductStyles>
      {/*<Link to={gatsbyPath}>*/}
        <GatsbyImage
          className="features__item--img"
          image={image}
          alt={alt}
        />
        {title && introduction && (
          <div className="features__item--content">
            {title && <h4>{title}</h4>}
            {introduction && <p>{introduction}</p>}
            {/*<Button text="Read More" as="span" arrow={false} />*/}
          </div>
        )}
      {/*</Link>*/}
    </FeaturedProductStyles>
  )
}

export default FeaturedProduct
